<template>
  <v-dialog
    v-model="ticketing.showProfilingHierarchy"
    persistent
    width="1100"
  >
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="4">
            <v-card-title>
              Request types
            </v-card-title>
            <div
              v-for="item in requestTypesArray"
              :key="item"
            >
              <v-checkbox
                v-model="ticketing.selectedRequestTypes['permission_name']"
                :label="item"
                :value="item"
              />
            </div>
          </v-col>
          <v-col cols="4">
            <v-card-title>
              Permissions
            </v-card-title>
            <div
              v-for="item in permissions"
              :key="item"
            >
              <v-checkbox
                v-model="ticketing.selectedPermissions['permission_name']"
                :label="item"
                :value="item"
              />
            </div>
          </v-col>
          <v-col cols="4">
            <v-card-title>
              Notifications
            </v-card-title>
            <div
              v-for="item in notifications"
              :key="item"
            >
              <v-checkbox
                v-model="ticketing.selectedNotifications['permission_name']"
                :label="item"
                :value="item"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          dark
          color="warning"
          @click="ticketing.showProfilingHierarchy = false"
        >
          Close
        </v-btn>
        <v-spacer />
        <v-btn
          dark
          color="color_green"
          @click="confirmProfiling()"
        >
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {
  permissionsArray,
  notificationsArray,
  log
} from '@/constants.js';

import { v4 as uuidv4 } from 'uuid';

export default {
  props: {
    profilingItem: {
      type: Object,
      default: null
    },
    selectedTicketingService: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      id: '',
      overlay: false
    };
  },

  computed: {
    ...mapState(['ticketing', 'services']),
    requestTypesArray() {
      return this.ticketing.ticketingServiceConfigurationDocumentType.document_type;
    },
    permissions() {
      return permissionsArray.permissions;
    },
    notifications() {
      return notificationsArray.notifications;
    }
  },
  methods: {
    ...mapActions(['createProfilingTicketingServiceAction', 'deleteProfilingTicketingServiceAction']),
    async confirmProfiling() {
      const hierarchyItemsCreated = { profiling: [], hierarchy: [] };
      const hierarchyItemsDeleted = { profiling: [], hierarchy: [] };


      this.createProfilingArray(
        this.requestTypesArray,
        this.ticketing.selectedRequestTypes,
        this.ticketing.oldSelectedRequestTypes,
        'requests'
      );
      this.createProfilingArray(
        permissionsArray.permissions,
        this.ticketing.selectedPermissions,
        this.ticketing.oldSelectedPermissions,
        'permissions'
      );
      this.createProfilingArray(
        notificationsArray.notifications,
        this.ticketing.selectedNotifications,
        this.ticketing.oldSelectedNotifications,
        'notifications'
      );

      this.ticketing.profilingArray.forEach(profiling => {
        profiling.service_id = this.selectedTicketingService.service_id;
        hierarchyItemsCreated.profiling.push(profiling);
      });

      this.ticketing.deletedProfilingArray.forEach(profiling => {
        profiling.service_id = this.selectedTicketingService.service_id;
        hierarchyItemsDeleted.profiling.push(profiling);
      });

      if (hierarchyItemsCreated.profiling.length > 0) {
        this.overlay = true;
        await this.createProfilingTicketingServiceAction(hierarchyItemsCreated);
        this.overlay = false;
      }

      if (hierarchyItemsDeleted.profiling.length > 0) {
        this.overlay = true;
        await this.deleteProfilingTicketingServiceAction(hierarchyItemsDeleted);
        this.overlay = false;
      }

      this.ticketing.showProfilingHierarchy = false;
    },
    createProfilingArray(constantArray, modelArray, oldModelArray, profilingType) {
      if (this.ticketing.storeProfilingTicketing.profiling.length > 0) {
        const createdArray = [];
        const deletedArray = [];

        for (let i = 0;i < modelArray.permission_name.length;i++) {
          const permission_name = modelArray.permission_name[i];
          const permission_id = modelArray.permission_id[i];
          createdArray.push({ permission_name: permission_name, permission_id: permission_id || '' });
        }

        for (let i = 0;i < oldModelArray.permission_name.length;i++) {
          const old_permission_name = oldModelArray.permission_name[i];
          const old_permission_id = oldModelArray.permission_id[i];

          const findItem = modelArray.permission_name.some((item) => item == old_permission_name);

          if (!findItem) {
            deletedArray.push({ permission_name: old_permission_name, permission_id: old_permission_id });
          }
        }

        createdArray.forEach(item => {
          this.id = uuidv4();

          if (item.permission_id === '') {
            if (profilingType == 'requests') {
              for (let i = 0;i < this.ticketing.ticketingServiceConfigurationDocumentType.document_type.length;i++) {
                const document_type = this.ticketing.ticketingServiceConfigurationDocumentType.document_type[i];
                const ticket_id = this.ticketing.ticketingServiceConfigurationDocumentType.ticket_id[i];

                if (item.permission_name == document_type) {
                  item.permission_name = `${item.permission_name}, ServiceCard.${ticket_id}.documentType`;
                }
              }
            }

            this.ticketing.profilingArray.findIndex(
              e =>
                e.node_id == this.profilingItem.node_id &&
                    e.permission_name == item.permission_name
            ) == -1 ?
              this.ticketing.profilingArray.push({
                permission_type: profilingType,
                permission_name: item.permission_name,
                permission_id: this.id,
                node_id: this.profilingItem.node_id
              }) : log.error('item exist');
          }
        });

        deletedArray.forEach(item => {
          this.ticketing.deletedProfilingArray.findIndex(
            e =>
              e.node_id == this.profilingItem.node_id &&
                    e.permission_name == item.permission_name
          ) == -1 ?
            this.ticketing.deletedProfilingArray.push({
              permission_type: profilingType,
              permission_name: item.permission_name,
              permission_id: item.permission_id,
              node_id: this.profilingItem.node_id
            }) : log.error('item exist');
        });
      } else {
        constantArray.forEach(item => {
          this.id = uuidv4();
          modelArray.permission_name.forEach(modelItem => {
            if (item == modelItem) {
              if (profilingType === 'requests') {
                for (let i = 0;i < this.ticketing.ticketingServiceConfigurationDocumentType.document_type.length;i++) {
                  const document_type = this.ticketing.ticketingServiceConfigurationDocumentType.document_type[i];
                  const ticket_id = this.ticketing.ticketingServiceConfigurationDocumentType.ticket_id[i];

                  if (modelItem == document_type) {
                    modelItem = `${modelItem}, ServiceCard.${ticket_id}.documentType`;
                  }
                }
              }

              this.ticketing.profilingArray.findIndex(
                e =>
                  e.node_id == this.profilingItem.node_id &&
                    e.permission_name == modelItem
              ) == -1
                ? this.ticketing.profilingArray.push({
                  permission_type: profilingType,
                  permission_name: modelItem,
                  permission_id: this.id,
                  node_id: this.profilingItem.node_id
                })
                : log.error('item exist');
            }
          });
        });
      }
    }
  }
};
</script>

<style scoped>
.v-input--selection-controls /deep/ {
  margin-top: 0 !important;
}
</style>
